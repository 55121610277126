// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// fonts
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #373e4b;
  --ion-color-primary-rgb: 55, 62, 75;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #303742;
  --ion-color-primary-tint: #4b515d;

  --ion-color-secondary: #e3ac24;
  --ion-color-secondary-rgb: 227, 172, 36;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #c89720;
  --ion-color-secondary-tint: #e6b43a;

  --ion-color-tertiary: #9ed7d0;
  --ion-color-tertiary-rgb: 158, 215, 208;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #8bbdb7;
  --ion-color-tertiary-tint: #a8dbd5;

  --ion-color-success: #a7c239;
  --ion-color-success-rgb: 167, 194, 57;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #93ab32;
  --ion-color-success-tint: #b0c84d;

  --ion-color-warning: #db4e3d;
  --ion-color-warning-rgb: 219, 78, 61;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #c14536;
  --ion-color-warning-tint: #df6050;

  --ion-color-danger: #db4e3d;
  --ion-color-danger-rgb: 219, 78, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c14536;
  --ion-color-danger-tint: #df6050;

  --ion-color-dark: #3b7c4f;
  --ion-color-dark-rgb: 59, 124, 79;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #346d46;
  --ion-color-dark-tint: #4f8961;

  --ion-color-medium: #3b7c4f;
  --ion-color-medium-rgb: 59, 124, 79;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #346d46;
  --ion-color-medium-tint: #4f8961;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-font-family: "Nunito Sans", sans-serif;
  // toolbar
  --ion-toolbar-background: #373e4b;
  --ion-toolbar-color: var(--ion-color-light);
  --flock-dark-background: var(--ion-toolbar-background);
}
