/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// optimised icons using https://icomoon.io
@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icons/icomoon.eot?abc123");
  src:
    url("/assets/fonts/icons/icomoon.eot?abc123#iefix") format("embedded-opentype"),
    url("/assets/fonts/icons/icomoon.ttf?abc123") format("truetype"),
    url("/assets/fonts/icons/icomoon.woff?abc123") format("woff"),
    url("/assets/fonts/icons/icomoon.svg?abc123#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.ff-icon {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-top: 1px;
  margin-left: 4px;
  font-size: 2rem;
}

.ff-icon-lamb:before {
  content: "\e901";
}
.ff-icon-cattle:before {
  content: "\e900";
}
.ff-icon-sheep:before {
  content: "\e908";
}

ion-item ion-spinner {
  display: block;
  margin: auto;
}

// modals
ion-modal ion-backdrop {
  --backdrop-opacity: 0.18;
}

.flock-no-padding-right {
  --padding-end: 0px;
}

.flock-text-medium {
  font-size: medium;
}

ion-item.ion-no-padding {
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

// toolbar
ion-toolbar.flock-toolbar {
  z-index: 1;
}

@media (min-width: 568px) {
  .hidden-xs-up {
    display: none !important;
  }
}

ion-input.password-input {
  .input-wrapper.sc-ion-input-md {
    padding-inline-end: 56px !important;
  }
}

ion-toolbar.flock-toolbar-squash {
  min-height: 200px;
}

ion-toolbar.flock-toolbar-squash-fixed {
  height: 200px;
}

ion-toolbar.flock-toolbar-top {
  z-index: 2;
}

ion-toolbar.flock-toolbar-desktop {
  min-height: 120px;
}

// footer
ion-footer.flock-footer-dark {
  background-color: var(--flock-dark-background);
}

// tabs
ion-tab-bar {
  --background: var(--ion-color-primary);
  --border: var(--ion-color-primary-tint);
  --color: var(--ion-color-tertiary);
  padding-top: 5px;
}

ion-tab-button {
  --color-selected: var(--ion-color-primary-light);
}

// login / register
img.flock-register-logo {
  max-width: 50%;
  margin-top: 30px;
}

ion-input.flock-dark-input {
  --background: var(--ion-color-primary-contrast);
  --color: var(--ion-color-primary);
}

// general
.flock-margin-top-large {
  margin-top: 40px;
}

.flock-margin-top-small {
  margin-top: 7px;
}

.flock-medium-font {
  font-size: medium;
}

.flock-small-font {
  font-size: 11px;
}

.flock-smaller-font {
  font-size: smaller;
}

.flock-data-item-highlighted {
  font-style: italic;
  font-weight: bold;
}

.flock-text-italic {
  font-style: italic;
}

.flock-bold-font {
  font-weight: bold;
}

.flock-padding-large {
  --padding-start: 30px;
  --padding-end: 30px;
  --padding-top: 30px;
  --padding-bottom: 30px;
}

.flock-card-margin {
  margin: 10px 10px 10px 10px;
}

ion-icon.flock-larger-icon {
  font-size: 3.5em;
}

ion-icon.flock-larger-icon-2 {
  font-size: 2.6em;
}

ion-button.flock-larger-button {
  font-size: 1em;
  min-height: 100px;
  font-weight: bold;
}

ion-button.flock-larger-button-2 {
  font-size: 1em;
  min-height: 80px;
  font-weight: bold;
  --padding-start: 0.1em;
  --padding-end: 0.1em;
}

ion-button.flock-smaller-button {
  font-size: 0.9em;
  min-height: 50px;
  font-weight: bold;
  --border-radius: 5px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
}

ion-button.flock-smallest-button {
  font-size: 0.8em;
  min-height: 55px;
  font-weight: bold;
}

ion-icon.flock-smaller-icon-1 {
  font-size: 2.1em;
  margin-right: 3px;
}

ion-icon.flock-smaller-icon-2 {
  font-size: 2.3em;
  margin-right: 3px;
}

ion-icon.flock-smaller-icon-3 {
  font-size: 1.6em;
}
ion-icon.flock-smaller-icon-4 {
  font-size: 1.6em;
  margin-right: 3px;
}

ion-label.flock-form-warning {
  font-style: italic !important;
  font-size: smaller !important;
  --color: var(--ion-color-danger) !important;
  font-weight: bold;
}

ion-label.flock-form-warning-dark {
  font-style: italic !important;
  font-size: smaller !important;
  font-weight: bold;
}

ion-grid.flock-dark-background {
  background-color: var(--ion-color-primary);
  padding-top: 0px !important;
}

ion-grid.flock-top-min-height {
  min-height: 220px;
}

ion-list.flock-dark-background {
  background-color: var(--ion-color-primary);
}

ion-footer.flock-footer {
  background-color: var(--ion-color-primary-contrast);
}

ion-toolbar.flock-footer {
  --background: var(--ion-color-primary-contrast);
}

ion-row.flock-table-top-border {
  border-bottom: 1px solid var(--ion-color-primary);
  margin-top: 0px;
  margin-bottom: 2px;
}

ion-item.flock-data-item {
  --padding-top: 2px;
  --padding-bottom: 2px;
}

.flock-required-field {
  color: #ff4d4d;
  font-weight: lighter;
  font-size: smaller;
}

.flock-optional-field {
  color: #686868;
  font-style: italic;
  font-size: smaller;
}

// dark item - helps with shading line
ion-item.ion-color-primary-dark {
  --ion-color-base: var(--ion-color-primary) !important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-light) !important;
  --ion-color-tint: var(--ion-color-primary-tint) !important;
}

ion-item {
  --border-color: var(--ion-color-tertiary);
}

// segment style
ion-segment-button.flock-segment {
  --background-checked: var(--ion-color-tertiary);
  --color-checked: var(--ion-color-tertiary-contrast);
  --border-color: var(--ion-color-tertiary);
  --indicator-color: transparent;
  font-weight: bold;
}

ion-segment-button.flock-segment-toolbar-1 {
  --color: var(--ion-color-primary);
  --color-checked: var(--ion-color-primary);
  --border-radius: 15px;
  --indicator-color: var(--ion-color-tertiary);
  font-weight: bold;
}

ion-segment.flock-segment-toolbar-1 {
  background-color: var(--ion-color-light-tint);
  // display: inline-block;
}

ion-segment-button.flock-segment-toolbar-2 {
  --color: var(--ion-color-primary);
  --color-checked: var(--ion-color-primary-contrast);
  --border-radius: 15px;
  --indicator-color: var(--ion-color-primary);
  font-weight: bold;
}

ion-segment.flock-segment-toolbar-2 {
  background-color: var(--ion-color-light-tint);
  // display: inline-block;
}

ion-segment-button.flock-segment-toolbar-3 {
  --color: var(--ion-color-primary);
  --color-checked: var(--ion-color-primary);
  --border-radius: 3px;
  --indicator-color: var(--ion-color-tertiary);
  font-weight: bold;
}

ion-segment.flock-segment-toolbar-3 {
  background-color: var(--ion-color-light-tint);
  // display: inline-block;
}

.slides-custom-bullets .swiper-pagination-bullets {
  bottom: 30px !important;
}

ion-text.ion-text-bold {
  font-weight: bold;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.flock-secondary-input {
  border: 1px solid #9ed7d0;
  border-radius: 5px;
  height: 35px;
  --padding-start: 5px;
}

.flock-alert {
  .alert-wrapper {
    max-width: 70vw;
  }

  .alert-head {
    h2 {
      font-size: 20px;
    }
  }

  .alert-message {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    white-space: pre-wrap;
    color: var(--ion-color-primary);
  }

  .alert-button-confirm {
    color: var(--ion-color-success);
  }

  .alert-button {
    font-weight: bold;

    &-danger {
      color: #ff0404;
    }

    &-cancel {
      color: #a7c239;
    }

    &-continue {
      color: #177e4b;
    }
  }
}

.modal-auto-height {
  --width: 90%;
  --height: max-content;
  --border-radius: 16px;
}

.bulk-upload-modal {
  --width: 60%;
  --height: 85%;
  --border-radius: 16px;
}

.app-confirm-button {
  --color: white;
  --background: var(--ion-color-success);
  --border-radius: 5px;
  --box-shadow: 4px 4px 10px rgb(0, 0, 0, 0.25);
}

.flock-app-card {
  background-color: white;
  color: var(--ion-color-primary);
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  padding: 5px 10px 10px 10px;
}

.flock-app-card-dark {
  background-color: var(--ion-color-primary);
  color: white;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  padding: 5px 10px 10px 10px;
}

.padding-l {
  &-0 {
    padding-left: 0px;
  }

  &-5 {
    padding-left: 5px;
  }

  &-10 {
    padding-left: 10px;
  }

  &-15 {
    padding-left: 15px;
  }

  &-20 {
    padding-left: 20px;
  }
}

.flock-placeholder-text {
  font-size: 16px;
  color: #373e4b;
}

ion-searchbar.flock-search-bar {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0px;
}

ion-icon.flock-animal-icon {
  margin-top: 1px;
  margin-left: 4px;
}

button.alert-button.danger {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.onboarding-alert .alert-sub-title {
  margin-top: 15px;
  margin-bottom: 15px;
}

.input-sm label {
  height: 15px;
}

.input-md {
  min-height: 40px !important;
}

.input-md .input-wrapper .label-text-wrapper {
  font-weight: 400;
}

ion-progress-bar.flock-amount-left {
  height: 8px;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 5px;
  --progress-background: var(--ion-color-primary);
  --background: rgba(var(--ion-color-tertiary-rgb), 0.3);
}

// ion-slides -> swiper
swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

div.input-with-fill {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 15px;
  color: var(--ion-color-primary);
}
div.input-with-fill--no-margin {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 0px !important;
  color: var(--ion-color-primary);
}

ion-modal.labels-modal {
  --height: auto;
}

ion-modal.lambing-modal {
  --height: auto;
}

ion-modal.height-auto-modal {
  --height: fit-content;
  --min-height: 200px;
  --max-height: 90%;
}

.fw-600 {
  font-weight: 600;
}

.flock-select {
  border-bottom: 0.55px solid #c8c7cc;
}

ion-modal.eid-info-modal {
  --height: auto;
  --border-radius: 8px;
}

ion-alert.metric-options .alert-radio-icon {
  // avoids the radio checkboxes from being displayed
  display: none !important;
}

.pac-item {
  font-size: 13px !important;
}

.pac-item-query {
  font-size: 15px !important;
}

.ff-equal-height-btn {
  height: 55px !important;
  white-space: normal;
  --padding-top: 10px;
  --padding-bottom: 10px;
}

ion-button {
  font-weight: bolder;
}

.contact-us-text {
  font-weight: lighter;
}

.outlier-indicator {
  margin-left: 4px;
  font-size: 14px;
  vertical-align: middle;
  margin-bottom: 3px;

  &.high {
    color: var(--ion-color-danger);
  }

  &.medium {
    color: var(--ion-color-secondary);
  }

  &.low {
    color: var(--ion-color-tertiary);
  }
}

.password-reset-modal {
  --height: auto;

  &::part(content) {
    // Prevent content from being cut off
    min-height: 800px;
  }

  ion-content {
    --background: var(--ion-color-white);
  }

  // Ensure handle is visible against white background
  &::part(handle) {
    background: var(--ion-color-medium-tint);
  }
}

ion-button.flock-button-clear {
  // we want a slight shadow and a prarimy background with alpha
  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.04);
  --background: rgba(var(--ion-color-primary-rgb), 0.03);
  --color: var(--ion-color-medium-tint) !important;
}

.flock-xs-font {
  font-size: 0.8rem;
}

.custom-more-popover {
  --width: auto;
  --min-width: 200px;
  --max-width: 300px;
  --height: auto;
  --background: var(--ion-color-primary);
  --box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  --backdrop-opacity: 0.4;
  --border-radius: 12px;
  --offset-y: 8px;
}

/* Remove arrow */
.custom-more-popover ion-popover::part(arrow) {
  display: none !important;
}

/* Target the content container */
.custom-more-popover .popover-content {
  border: 2px solid var(--ion-color-primary-tint);
  border-radius: 12px !important;
  background: var(--ion-color-primary) !important;
}

/* Force override any white background */
.custom-more-popover .popover-content .popover-viewport {
  background: var(--ion-color-primary) !important;
}

/* Additional specificity to override Ionic styles */
ion-popover.custom-more-popover::part(content) {
  background: var(--ion-color-primary) !important;
  border: 2px solid var(--ion-color-primary-tint) !important;
  border-radius: 12px !important;
}

ion-popover.custom-more-popover::part(arrow) {
  display: none !important;
}

ion-fab-button.multi-select-fab[title] {
  position: relative;
}

ion-fab-button.multi-select-fab[title]::after {
  position: absolute;
  content: attr(title);
  z-index: 1;
  right: 40px;
  bottom: 4px;
  padding: 9px;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.flock-backdrop {
  background: rgba(0, 0, 0, 0.75);
  opacity: 1;
  z-index: 1000;
}

.flock-backdrop-hidden {
  opacity: 0;
  z-index: -1;
}

.flock-filter-chip {
  margin-left: 0;
}

ion-fab.flock-scroll-to-top-fab {
  z-index: 1000;
  bottom: 44px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;

  &.visible {
    opacity: 0.85;
    pointer-events: auto;
  }

  &:hover {
    opacity: 1;
  }
}

.flock-heading-container-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}

// FAB attention animation
@keyframes pulse-grow-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--ion-color-tertiary-rgb), 0.4);
    border-radius: 20%;
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(var(--ion-color-tertiary-rgb), 0.2);
    border-radius: 20%;
    transform: scale(1.1);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--ion-color-tertiary-rgb), 0);
    border-radius: 20%;
    transform: scale(1);
  }
}

.attention-animation.main-fab ion-fab-button {
  animation: pulse-grow-shadow 1s ease-in-out 2;
}
